import {Slide} from "react-awesome-reveal";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Banner() {
    const { t } = useTranslation();
    const renderHtml = (content) => {
        return { __html: content }
    }
    return (
        <div className={"app-banner"}>
           <div className={"opacity"}></div>
            <div className={"content"}>
                <Slide direction={"left"} triggerOnce={true} delay={125}>
                    <h1 dangerouslySetInnerHTML={renderHtml(t('slogan'))}></h1>
                </Slide>
                <Slide direction={"up"} delay={150} triggerOnce={true}>
                    <NavLink to={"/products"} reloadDocument={true}>{t('ourProducts')}</NavLink>
                </Slide>
            </div>
        </div>
    )
}