import {NavLink} from "react-router-dom";
import Photo from "../../images/8seguros.jpg";
import Benefit8Seguros from "../../components/8segurosBenefit";
import Seguros8Price from "../../components/8SegurosPrice";
import {useTranslation} from "react-i18next";
import {Fade, Slide} from "react-awesome-reveal";

export default function Seguros8() {
    const {t} = useTranslation();
    return (
        <>
            <div className="page-header">
                <div className="opacity"></div>
                <h1>{t('multipleInsurance')}</h1>
                <ul className="submenu">
                    <li><NavLink reloadDocument={true} to="/">{t('home')}</NavLink></li>
                    <li><NavLink reloadDocument={false} to="/products">{t('products')}</NavLink></li>
                    <li>{t('multipleInsurance')}</li>
                </ul>
            </div>
            <div className="page-content">
                <div className="content">
                    <div className={"content-images"}>
                        <Slide direction={"up"} triggerOnce={true}>
                            <img src={Photo} alt={"Gastos Finales"}/>
                        </Slide>
                        <Fade delay={350} triggerOnce={true}>
                            <div className="d-flex-center">
                                <h1>{t('multipleInsurance')}</h1>
                                <p className={"page-text-content"}>
                                    {t('multipleInsuranceDetailGeneral')}
                                </p>
                                <NavLink reloadDocument={true} className="btn-call-to-action"
                                         to="/contact">{t('contactUs')}</NavLink>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <Benefit8Seguros/>
            <Seguros8Price/>
        </>)
}