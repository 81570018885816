import Banner from "../components/banner";
import Services from "../components/services";
import SelectUs from "../components/selectus";
import CTA1 from "../components/cta1";

export default function HomePage() {
    return (
        <>
            <Banner/>
            <Services/>
            <CTA1/>
            <SelectUs/>
        </>
    )
}