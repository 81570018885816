import Logo from '../images/img.png';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ChangeLanguage from "./changeLanguage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faX} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";

export default function Header() {
    const { t } = useTranslation();
    const [openMobile, setOpenMobile] = useState(false);
    return (
        <header className={"app-header"}>
            <div className={"app-logo"}>
                <img src={Logo} alt="logo"/>
            </div>
            <div className={`app-navigation ${openMobile ? 'active' : ''}`}>
                <ul>
                    <li>
                        <NavLink reloadDocument={true} className={({isActive}) => isActive ? "active" : ""}
                                 to={"/"}> {t("home")}</NavLink>
                    </li>
                    <li>
                        <NavLink reloadDocument={true} className={({isActive}) => isActive ? "active" : ""}
                                 to={"/about"}>{t("aboutUs")}</NavLink>
                    </li>
                    <li>
                        <NavLink reloadDocument={true} className={({isActive}) => isActive ? "active" : ""}
                                 to={"/products"}>{t("products")}</NavLink>
                    </li>
                    {/*<li>*/}
                    {/*    <NavLink reloadDocument={true} className={({isActive}) => isActive ? "active" : ""}*/}
                    {/*             to={"/testimonials"}>{t("testimonials")}</NavLink>*/}
                    {/*</li>*/}
                    <li>
                        <NavLink reloadDocument={true} className={({isActive}) => isActive ? "active" : ""}
                                 to={"/contact"}>{t("contact")}</NavLink>
                    </li>
                    <li>
                        <ChangeLanguage/>
                    </li>
                </ul>
                <div className={"close-mobile-menu"} onClick={() => setOpenMobile(prevState => !prevState)}>
                    <FontAwesomeIcon icon={faX} />
                </div>
            </div>
            <div className={"mobile-menu"} onClick={() => setOpenMobile(prevState => !prevState)}>
                <FontAwesomeIcon icon={faBars}/>
            </div>
        </header>
    )
}