import Logo from '../images/img.png';
export default function Footer() {
    return (
      <footer>
          <div></div>
          <div className="footer-logo">
                <img src={Logo} alt="Logo"/>
          </div>
          <div></div>
      </footer>
    );
}