import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Fade} from "react-awesome-reveal";

export default function Services() {
    const {t} = useTranslation();
    return (<section className="services">
            <h1 className="title">{t('productWeOffer')}</h1>
            <p className={"subtitle"}>{t('productWeOfferDetail')}</p>
            <ul className="items">
                <Fade delay={250} triggerOnce={true}>
                    <li>

                        <span className="title">{t('cancerInsurance')}</span>
                        <p>{t('cancerInsuranceDetail')}</p>
                        <NavLink reloadDocument={true} to="/products/cancer">{t('viewDetail')}</NavLink>
                    </li>
                </Fade>
                <Fade delay={350} triggerOnce={true}>
                    <li>
                        <span className="title">{t('multipleInsurance')}</span>
                        <p>{t('multipleInsuranceDetail')}</p>
                        <NavLink reloadDocument={true} to="/products/8seguros">{t('viewDetail')}</NavLink>
                    </li>
                </Fade>
                <Fade delay={450} triggerOnce={true}>
                    <li>
                        <span className="title">{t('finalExpense')}</span>
                        <p>{t('finalExpenseDetail')}</p>
                        <NavLink reloadDocument={true} to="/products/gastosfinales">{t('viewDetail')}</NavLink>
                    </li>
                </Fade>
                <Fade delay={550} triggerOnce={true}>
                    <li>
                        <span className="title">{t('retirement')}</span>
                        <p>{t('retirementDetail')}</p>
                        <NavLink reloadDocument={true} to="/products/retirement">{t('viewDetail')}</NavLink>
                    </li>
                </Fade>
            </ul>
        </section>)
}