import { useState, useEffect } from 'react';
import i18n from '../i18n';

const useI18n = () => {
    const [language, setLanguage] = useState(localStorage.getItem('lang') || 'es');

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('lang', lng);
        setLanguage(lng);
    };

    useEffect(() => {
        i18n.on('languageChanged', (lng) => setLanguage(lng));
        return () => {
            i18n.off('languageChanged');
        };
    }, []);

    return [language, changeLanguage];
};

export default useI18n;
