import ContactForm from "../components/contactForm";
import Maps from "../components/maps";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function ContactPage() {
    const { t } = useTranslation();
    return (
        <>
            <div className="page-header">
                <div className="opacity"></div>
                <h1>{t('contact')}</h1>
                <ul className="submenu">
                    <li><NavLink to="/">{t('home')}</NavLink></li>
                    <li>{t('contact')}</li>
                </ul>
            </div>
            <div className="page-content">
                <ContactForm/>
            </div>
            <Maps/>
        </>
    )
}