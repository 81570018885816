import i18n from "i18next";
import useI18n from "../hook/hooki18n";

export default function ChangeLanguage() {
    const [language, changeLanguage] = useI18n();

    const LANGUAGES = [{
        code: 'en',
        label: 'English'
    }, {
        code: 'es',
        label: 'Spanish'
    }];

    return (
        <select defaultValue={language} onChange={(e) => changeLanguage(e.target.value)}>
            {LANGUAGES.map(({code, label}) => (
                <option key={code} value={code}>
                    {label}
                </option>
            ))}
        </select>
    )
}