import HomePage from "./pages/home";
import ContactPage from "./pages/contact";
import App from "./App";
import Products from "./pages/products";
import Testimonials from "./pages/testimonials";
import About from "./pages/about";
import Cancer from "./pages/products/cancer";
import GastosFinales from "./pages/products/gastosfinales";
import Retirement from "./pages/products/retirement";
import Seguros8 from "./pages/products/8seguros";

const router = [
    {
        path: '/',
        element: <App/>,
        children: [
            {
                path: "/",
                element: <HomePage/>,
            },
            {
                path: 'contact',
                element: <ContactPage/>,
            },
            {
                path: "about",
                element: <About/>,
            },
            {
                path: "testimonials",
                element: <Testimonials/>,
            },
            {
                path: "products",
                exact: true,
                element: <Products/>,
            },
            {
                path: "/products/cancer",
                element: <Cancer/>,
            },
            {
                path: "/products/gastosfinales",
                element: <GastosFinales/>,
            },
            {
                path: "/products/retirement",
                element: <Retirement/>,
            },
            {
                path: "/products/8seguros",
                element: <Seguros8/>,
            }
        ]
    }
];

export default router;