import {NavLink} from "react-router-dom";
import Photo from "../../images/gastos-finales.jpg";
import {useTranslation} from "react-i18next";
import {Fade, Slide} from "react-awesome-reveal";

export default function GastosFinales() {
    const { t } = useTranslation();
    return (
        <>
            <div className="page-header">
                <div className="opacity"></div>
                <h1>{t('finalExpense')}</h1>
                <ul className="submenu">
                    <li><NavLink reloadDocument={true} to="/">{t('home')}</NavLink></li>
                    <li><NavLink reloadDocument={false} to="/products">{t('products')}</NavLink></li>
                    <li>{t('finalExpense')}</li>
                </ul>
            </div>
            <div className="page-content">
                <div className="content">
                    <div className={"content-images"}>
                        <Slide direction={"up"} triggerOnce={true}>
                            <img src={Photo} alt={"Gastos Finales"}/>
                        </Slide>
                        <Fade delay={350} triggerOnce={true}>
                            <div className="d-flex-center">
                                <h1>{t('finalExpense')}</h1>
                                <p className={"page-text-content"}>
                                    {t('finalExpenseDetailGeneral')}
                                </p>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </>
    )
}