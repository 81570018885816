import {NavLink} from "react-router-dom";
import Services from "../components/services";
import {useTranslation} from "react-i18next";
export default function About() {
    const { t } = useTranslation();
    return (
        <>
            <div className="page-header">
                <div className="opacity"></div>
                <h1>{t('aboutUs')}</h1>
                <ul className="submenu">
                    <li><NavLink to="/">{t('home')}</NavLink></li>
                    <li>{t('aboutUs')}</li>
                </ul>
            </div>
            <div className="page-content">
                <div className={"content"}>
                    <h1>{t('aboutUsQuestion')}</h1>
                    <p>{t('aboutUsQuestionDetail1')}</p>
                    <p>{t('aboutUsQuestionDetail2')}</p>
                </div>
            </div>
            <Services/>
        </>
    )
}