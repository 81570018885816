import {NavLink} from "react-router-dom";
import CancerPhoto from "../../images/cancer.jpg";
import {useTranslation} from "react-i18next";
import {Fade, Slide} from "react-awesome-reveal";

export default function Cancer() {
    const { t } = useTranslation();
    return (
        <>
            <div className="page-header">
                <div className="opacity"></div>
                <h1>{t('cancerInsurance')}</h1>
                <ul className="submenu">
                    <li><NavLink to="/">{t('home')}</NavLink></li>
                    <li><NavLink reloadDocument={false} to="/products">{t('products')}</NavLink></li>
                    <li>{t('cancerInsurance')}</li>
                </ul>
            </div>
            <div className="page-content">
                <div className="content">
                    <div className={"content-images"}>
                        <Slide direction={"up"} triggerOnce={true}>
                            <img src={CancerPhoto} alt={"Seguro de Cancer"}/>
                        </Slide>
                        <Fade delay={350} triggerOnce={true}>
                            <div className="d-flex-center">
                                <h1>{t('cancerInsurance')}</h1>
                                <p className={"page-text-content"}>{t('cancerInsuranceDetailGeneral')}</p>
                                <NavLink reloadDocument={true} className="btn-call-to-action"
                                         to="/contact">{t('contactUs')}</NavLink>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </>
    )
}