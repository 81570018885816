import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEnvelope, faLocationDot, faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {
    faFacebook, faInstagram, faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {useState} from "react";

const model = {
    name: "", email: "", phoneNumber: "", subject: "", message: "",
};

export default function ContactForm(props) {
    const {t} = useTranslation();
    const [data, setData] = useState(model);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        fetch("/api/contact.php", {
            method: "POST", headers: {
                "Content-Type": "application/json",
            }, body: JSON.stringify({
                name: data.name,
                email: data.email,
                phoneNumber: data.phoneNumber,
                subject: data.subject,
                text: data.message,
            }),
        })
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    setSuccess(true);
                    setError(false);
                    setData(model);
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
                console.error("Error:", error);
            });
    };

    const onChange = (e) => {
        setData((prevState) => {
            return {
                ...prevState, [e.target.name]: e.target.value,
            };
        });
    };

    return (<section className={"contact"}>
        <div className={"content"}>
            <div className={"contact-info"}>
                <div className={"info-item"}>
                    <div className={"item-icon"}>
                        <FontAwesomeIcon icon={faPhone}/>
                    </div>
                    <div className={"item-info"}>
                        <p>{t("phoneNumber")}</p>
                        <span>787-658-6122</span>
                    </div>
                </div>
                <div className={"info-item"}>
                    <div className={"item-icon"}>
                        <FontAwesomeIcon icon={faEnvelope}/>
                    </div>
                    <div className={"item-info"}>
                        <p>{t("email")}</p>
                        <span>
                <a href="mailto:0ffice@fsgseguros.com">0ffice@fsgseguros.com</a>
              </span>
                    </div>
                </div>
                <div className={"info-item"}>
                    <div className={"item-icon"}>
                        <FontAwesomeIcon icon={faLocationDot}/>
                    </div>
                    <div className={"item-info"}>
                        <p>{t("address")}</p>
                        <span>
                452 avenida Ponce de Leon, Hato Rey, San Juan, 00919. Suite 410
              </span>
                    </div>
                </div>
                <div className={"info-item"}>
                    <ul className={"social-medias"}>
                        <li>
                            <a href="https://facebook.com/segurosfsg">
                                <FontAwesomeIcon icon={faFacebook}/>
                            </a>
                        </li>
                        <li>
                            <a href="https://instagram.com/fsg_seguros">
                                <FontAwesomeIcon icon={faInstagram}/>
                            </a>
                        </li>
                        <li>
                            <a href="https://web.whatsapp.com/send?phone=+1%20787%202334871">
                                <FontAwesomeIcon icon={faWhatsapp}/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={"contact-form"}>
                <form onSubmit={onSubmit}>
                    <h4>{t("sendMessage")}</h4>
                    <div className={"fields"}>
                        <div>
                            <input
                                type="text"
                                placeholder={t("name")}
                                value={data.name}
                                disabled={loading}
                                name="name"
                                onChange={onChange}
                                required={true}
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder={t("email")}
                                value={data.email}
                                disabled={loading}
                                name="email"
                                onChange={onChange}
                                required={true}
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder={t("phoneNumber")}
                                value={data.phoneNumber}
                                disabled={loading}
                                name="phoneNumber"
                                onChange={onChange}
                                required={true}
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder={t("subject")}
                                disabled={loading}
                                value={data.subject}
                                name="subject"
                                onChange={onChange}
                                required={true}
                            />
                        </div>
                        <div>
                            <textarea
                                rows={5}
                                placeholder={t("message")}
                                disabled={loading}
                                name="message"
                                value={data.message}
                                onChange={onChange}
                                required={true}
                            />
                        </div>
                    </div>
                    <p className={"contact-term"}>{t('contactTerm')}</p>
                    <button className={"button-send"} disabled={loading}>
                        {t("sendMessage")}
                    </button>
                    {success ? (<p style={{color: "green"}}>{t("successContact")}</p>) : null}
                    {error ? <p style={{color: "red"}}>{t("errorContact")}</p> : null}
                </form>
            </div>
        </div>
    </section>);
}
