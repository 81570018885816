import {faPeopleGroup, faUser, faUserGroup} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Navigate, NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Fade} from "react-awesome-reveal";

export default function Seguros8Price() {
    const { t } = useTranslation();
    return (
        <section className={"prices"}>
            <h2>{t('prices')}</h2>
            <div className={"items"}>
                <div className={"item"}>
                    <Fade delay={250} triggerOnce={true}>
                        <div className={"icon"}>
                            <FontAwesomeIcon icon={faPeopleGroup}/>
                        </div>
                        <h2 className={"title"}>{t('family')}</h2>
                        <p className={"description"}>{t('familyDetail')}</p>
                        <p>{t('priceFrom')}</p>
                        <p className={"price"}>$75.25/mo</p>
                        <NavLink to={"/contact"} reloadDocument={true}>{t('contactUs')}</NavLink>
                    </Fade>
                </div>
                <div className={"item"}>
                    <Fade triggerOnce={true} delay={150}>
                        <div className={"icon"}>
                            <FontAwesomeIcon icon={faUserGroup}/>
                        </div>
                        <h2 className={"title"}>{t('couple')}</h2>
                        <p className={"description"}>{t('coupleDetail')}</p>
                        <p>{t('priceFrom')}</p>
                        <p className={"price"}>$58.50/mo</p>
                        <NavLink to={"/contact"} reloadDocument={true}>{t('contactUs')}</NavLink>
                    </Fade>
                </div>
                <div className={"item"}>
                    <Fade delay={350} triggerOnce={true}>
                        <div className={"icon"}>
                            <FontAwesomeIcon icon={faUser}/>
                        </div>
                        <h2 className={"title"}>{t('individual')}</h2>
                        <p>{t('individualDetail')}</p>
                        <p>{t('priceFrom')}</p>
                        <p className={"price"}>$44.50/mo</p>
                        <NavLink to={"/contact"} reloadDocument={true}>{t('contactUs')}</NavLink>
                    </Fade>
                </div>
            </div>
        </section>
    )
}