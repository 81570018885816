import {useTranslation} from "react-i18next";
import {Fade} from "react-awesome-reveal";

export default function Benefit8Seguros() {
    const {t} = useTranslation();
    return (
        <div className={"benefits"}>
            <h2>{t('getAllBenefit')}</h2>
            <div className={"items"}>
                <div className={"item"}>
                    <Fade>
                        <div className={"icon"}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" id="1617928251"
                                 className="svg u_1617928251" data-icon-name="wp-dna">
                                <path d="M49,35c0-6.4-4.5-10.3-9.6-13.2c5.1-2.5,9.6-5.1,9.6-9.8c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2c0,0.7-0.3,1.4-0.9,2H25.9
	c-0.5-0.6-0.9-1.3-0.9-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2c0,4.7,4.5,7.3,9.6,9.8C25.5,24.7,21,28.6,21,35c0,6.4,4.5,10.3,9.6,13.2
	C25.5,50.7,21,53.3,21,58c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2c0-0.7,0.3-1.4,0.9-2h18.3c0.5,0.6,0.9,1.3,0.9,2c0,1.1,0.9,2,2,2
	c1.1,0,2-0.9,2-2c0-4.7-4.5-7.3-9.6-9.8C44.5,45.3,49,41.4,49,35z M43.3,30c0.9,1.2,1.5,2.5,1.6,4H25.1c0.2-1.5,0.8-2.8,1.6-4H43.3z
	 M28.6,28c1.8-1.5,4-2.8,6.4-4c2.4,1.2,4.6,2.5,6.4,4H28.6z M26.7,40c-0.9-1.2-1.5-2.5-1.6-4h19.9c-0.2,1.5-0.8,2.8-1.6,4H26.7z
	 M41.4,42c-1.8,1.5-4,2.8-6.4,4c-2.4-1.2-4.6-2.5-6.4-4H41.4z M33.9,19c-2-1-4-2-5.6-3h13.4c-1.6,1-3.6,2-5.6,3
	c-0.4,0.2-0.7,0.3-1.1,0.5C34.6,19.3,34.3,19.1,33.9,19z M36.1,51c2,1,4,2,5.6,3H28.3c1.6-1,3.6-2,5.6-3c0.4-0.2,0.7-0.3,1.1-0.5
	C35.4,50.7,35.7,50.9,36.1,51z" id="1062789679"></path>
                            </svg>
                        </div>
                        <div className={"content"}>
                            <h1>{t('cancer')}</h1>
                            <p>{t('cancerDetail')}</p>
                        </div>
                    </Fade>
                </div>

                <div className={"item"}>
                    <Fade delay={350} triggerOnce={true}>
                        <div className={"icon"}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" id="1282299126"
                                 className="svg u_1282299126" data-icon-name="wp-wounded_thin">
                                <path d="M58.5,48.3c-0.4-2.5-2-4.6-4.3-5.5l-11.3-4.5L42,35.8v-1.7l1.5-4.2c0.5-0.1,1.3-0.3,1.9-0.8c1.1-0.9,1.7-2.3,1.7-3.8
	c0-1.2-0.5-2-0.9-2.4c-0.4-0.3-0.8-0.6-1.1-0.7V18c0-4.7-4.1-8-10-8s-10,3.3-10,8v4.2c-0.4,0.1-0.8,0.3-1.1,0.7
	C23.5,23.3,23,24,23,25.3c0,1.6,0.6,2.9,1.7,3.8c0.6,0.5,1.3,0.7,1.9,0.8l1.5,4.2v1.7l-0.8,2.4l-11.3,4.5c-2.3,0.9-4,3-4.3,5.5
	L10,60h50L58.5,48.3z M45.6,41.5l3.5,1.4L51.8,58H41.3L45.6,41.5z M43,18v2.1l-16,1.8v-1.1l15.9-3.5C43,17.5,43,17.7,43,18z M35,12
	c3.4,0,6.1,1.3,7.3,3.4L27,18.8V18C27,14.5,30.3,12,35,12z M28.8,39.8l1.2-3.6v-2.3L28,28h-0.7c-0.2,0-0.9-0.1-1.3-0.4
	c-0.6-0.5-1-1.4-1-2.3c0-1,0.5-1.2,1-1.3l1,0v-0.1l16-1.8V23l0,1l1,0c0.5,0,1,0.2,1,1.3c0,1-0.3,1.8-1,2.3C43.6,27.9,43,28,42.8,28
	H42l-2,5.8v2.3l1.2,3.6l1.5,0.6C40.8,42.7,38,44,35,44c-3,0-5.8-1.3-7.7-3.6L28.8,39.8z M12.3,58l1.2-9.4c0.3-1.8,1.4-3.3,3.1-3.9
	l8.8-3.5c2.3,3.1,5.8,4.9,9.6,4.9c3.1,0,6.1-1.2,8.3-3.3L41.3,50H31c-4.5,0-8,3.5-8,8H12.3z M31,52h9.8l-1.6,6H25
	C25,54.6,27.6,52,31,52z M53.8,58l-2.5-14.2l2.1,0.8c1.7,0.7,2.8,2.2,3.1,3.9l1.2,9.4H53.8z" id="1147975347"></path>
                            </svg>
                        </div>
                        <div className={"content"}>
                            <h1>{t('accident')}</h1>
                            <p>{t('accidentDetail')}</p>
                        </div>
                    </Fade>
                </div>

                <div className={"item"}>
                    <Fade delay={450} triggerOnce={true}>
                        <div className={"icon"}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" id="1833467967"
                                 className="svg u_1833467967" data-icon-name="wp-hospital_thin">
                                <g id="1887599385">
                                    <path d="M26,10v14H10v36h50V10H26z M12,26h14v4H12V26z M12,32h14v2H12V32z M12,58V36h14v22H12z M40,58v-8h6v8H40z M58,58H48V48H38
		v10H28V36h30V58z M58,34H28v-2h30V34z M58,30H28V12h30V30z" id="1170831061"></path>
                                    <polygon
                                        points="42,26 44,26 44,22 48,22 48,20 44,20 44,16 42,16 42,20 38,20 38,22 42,22 	"
                                        id="1119539712"></polygon>
                                </g>
                            </svg>
                        </div>
                        <div className={"content"}>
                            <h1>{t('hospitalization')}</h1>
                            <p>{t('hospitalizationDetail')}</p>
                        </div>
                    </Fade>
                </div>

                <div className={"item"}>
                    <Fade delay={550} triggerOnce={true}>
                        <div className={"icon"}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" id="1771832382"
                                 className="svg u_1771832382" data-icon-name="wp-stethoscope_thin">
                                <path d="M53,50c-1.8,0-3.4,1-4.2,2.4c-4.9-1.7-9.8-2.9-14.8-3.7V36.9c8.3-1.1,13-16.1,13-20.9c0-3.1-1.9-5-5.2-5h-1.1
	c-0.3-0.6-1-1-1.7-1c-1.1,0-2,0.9-2,2s0.9,2,2,2c0.7,0,1.4-0.4,1.7-1h1.1c3.2,0,3.2,2.3,3.2,3c0,4.7-4.8,19-12,19
	c-7.2,0-12-14.3-12-19c0-0.7,0-3,3.2-3h1.1c0.3,0.6,1,1,1.7,1c1.1,0,2-0.9,2-2s-0.9-2-2-2c-0.7,0-1.4,0.4-1.7,1h-1.1
	c-3.2,0-5.2,1.9-5.2,5c0,4.9,4.7,19.8,13,20.9v11.6c-2.7-0.3-5.4-0.5-8-0.5c-10.8,0-12,4.2-12,6c0,3.8,4.1,6,11,6c6.4,0,11-3.8,11-9
	v-0.2c4.7,0.7,9.4,1.9,14.1,3.5c0,0.2-0.1,0.5-0.1,0.7c0,2.8,2.2,5,5,5c2.8,0,5-2.2,5-5S55.8,50,53,50z M32,51c0,4.1-3.8,7-9,7
	c-5.5,0-9-1.5-9-4c0-2.5,3.7-4,10-4c2.6,0,5.3,0.2,8,0.5V51z M53,58c-1.7,0-3-1.3-3-3s1.3-3,3-3c1.7,0,3,1.3,3,3S54.7,58,53,58z"
                                      id="1081080047"></path>
                            </svg>
                        </div>
                        <div className={"content"}>
                            <h1>{t('fearedDiseases')}</h1>
                            <p>{t('fearedDiseasesDetail')}</p>
                        </div>
                    </Fade>
                </div>

                <div className={"item"}>
                    <Fade delay={650} triggerOnce={true}>
                        <div className={"icon"}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" id="1715090676"
                                 className="svg u_1715090676" data-icon-name="fa-heart-o">
                                <path fill="inherit"
                                      d="M1664 596q0-81-21.5-143t-55-98.5-81.5-59.5-94-31-98-8-112 25.5-110.5 64-86.5 72-60 61.5q-18 22-49 22t-49-22q-24-28-60-61.5t-86.5-72-110.5-64-112-25.5-98 8-94 31-81.5 59.5-55 98.5-21.5 143q0 168 187 355l581 560 580-559q188-188 188-356zM1792 596q0 221-229 450l-623 600q-18 18-44 18t-44-18l-624-602q-10-8-27.5-26t-55.5-65.5-68-97.5-53.5-121-23.5-138q0-220 127-344t351-124q62 0 126.5 21.5t120 58 95.5 68.5 76 68q36-36 76-68t95.5-68.5 120-58 126.5-21.5q224 0 351 124t127 344z"
                                      id="1391511901"></path>
                            </svg>
                        </div>
                        <div className={"content"}>
                            <h1>{t('transplant')}</h1>
                            <p>{t('transplantDetail')}</p>
                        </div>
                    </Fade>
                </div>

                <div className={"item"}>
                    <Fade delay={750} triggerOnce={true}>
                        <div className={"icon"}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" id="1703556919"
                                 className="svg u_1703556919" data-icon-name="li_thermometer">
                                <g id="1925312993">
                                    <path d="M50,11.9c-7.5,0-13.5,6.1-13.5,13.5v36.8c0,4.5,2.3,8.8,6.1,11.3v7.2c0,4.1,3.3,7.4,7.4,7.4c4.1,0,7.4-3.3,7.4-7.4v-7.2
		c3.8-2.5,6.1-6.7,6.1-11.3V25.4C63.5,18,57.4,11.9,50,11.9z M61.1,62.3c0,3.9-2.1,7.5-5.5,9.5l-0.6,0.4v8.5c0,2.7-2.2,4.9-4.9,4.9
		c-2.7,0-4.9-2.2-4.9-4.9v-8.5l-0.6-0.4c-3.5-2-5.5-5.6-5.5-9.5V25.4c0-6.1,5-11.1,11.1-11.1s11.1,5,11.1,11.1V62.3z"
                                          id="1316270112"></path>
                                    <polygon points="51.2,19.3 48.8,19.3 48.8,24.2 43.9,24.2 43.9,26.7 48.8,26.7 48.8,36.5 43.9,36.5 43.9,39 48.8,39 48.8,48.8
		43.9,48.8 43.9,51.2 48.8,51.2 48.8,61.1 43.9,61.1 43.9,63.5 48.8,63.5 48.8,68.4 51.2,68.4 51.2,63.5 56.1,63.5 56.1,61.1
		51.2,61.1 51.2,51.2 56.1,51.2 56.1,48.8 51.2,48.8 51.2,39 56.1,39 56.1,36.5 51.2,36.5 51.2,26.7 56.1,26.7 56.1,24.2 51.2,24.2
			" id="1247278970"></polygon>
                                </g>
                            </svg>
                        </div>
                        <div className={"content"}>
                            <h1>{t('inability')}</h1>
                            <p>{t('inabilityDetail')}</p>
                        </div>
                    </Fade>
                </div>

                <div className={"item"}>
                    <Fade delay={950} triggerOnce={true}>
                        <div className={"icon"}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" id="1041210468"
                                 className="svg u_1041210468" data-icon-name="wp-bed">
                                <path d="M61,24c-0.6,0-1,0.4-1,1v11h-2v-7c0-2.8-2.2-5-5-5H25c-1.3,0-2.4,0.5-3.3,1.3C21,23.4,19.2,22,17,22c-2.8,0-5,2.2-5,5
	s2.2,5,5,5c1.1,0,2.2-0.4,3-1v1h-3h-7V21c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1v28c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-7h50v7
	c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1V25C62,24.4,61.6,24,61,24z M25,26h28c1.7,0,3,1.3,3,3v7H22v-7C22,27.3,23.3,26,25,26z M17,30
	c-1.7,0-3-1.3-3-3s1.3-3,3-3c1.7,0,3,1.3,3,3S18.7,30,17,30z" id="1649487665"></path>
                            </svg>
                        </div>
                        <div className={"content"}>
                            <h1>{t('convalescence')}</h1>
                            <p>{t('convalescenceDetail')}</p>
                        </div>
                    </Fade>
                </div>

                <div className={"item"}>
                    <Fade delay={1050} triggerOnce={true}>
                        <div className={"icon"}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" id="1829159800"
                                 className="svg u_1829159800" data-icon-name="wp-church_thin">
                                <path d="M58.5,45.2L46,37v-4c0-0.2-0.1-0.5-0.3-0.7L36,21.6V16h5v-2h-5v-4h-2v4h-5v2h5v5.6l-9.7,10.7C24.1,32.5,24,32.8,24,33v4
	l-12.5,8.2l1.1,1.7l3.5-2.3V60h38V44.6l3.5,2.3L58.5,45.2z M42.7,32H27.3l7.7-8.5L42.7,32z M18,58V43.3l6-3.9V58H18z M33,58v-6
	c0-1.1,0.9-2,2-2s2,0.9,2,2v6H33z M39,58v-6c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4v6h-5V34h18v24H39z M52,58h-6V39.4l6,3.9V58z"
                                      id="1277750288"></path>
                            </svg>
                        </div>
                        <div className={"content"}>
                            <h1>{t('funeralExpense')}</h1>
                            <p>{t('funeralExpenseDetail')}</p>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    )
}