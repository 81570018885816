import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

export default function CTA1() {
    const { t } = useTranslation();
    return (
        <div className="cta">
            <h3>{t('cta1')}</h3>
            <NavLink to={"contact"} reloadDocument={true} className={"btn-call-to-action-white"}>{t('contactUs')}</NavLink>
        </div>
    )
}