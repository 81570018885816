import Family from '../images/family-1.jpg';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Fade, Slide} from "react-awesome-reveal";

export default function SelectUs() {
    const { t } = useTranslation();
    return (
        <section className="select-us">
            <div className="item">
                <div className="content">
                    <Fade delay={250} triggerOnce={true}>
                        <h1 className="title">{t('aboutUsQuestion')}</h1>
                        <p>{t('aboutUsQuestionDetail1')}</p>
                        <p style={{marginBottom: '30px'}}>{t('aboutUsQuestionDetail2')}</p>
                        <NavLink reloadDocument={true} className="btn-call-to-action"
                                 to="/contact">{t('contact')}</NavLink>
                    </Fade>
                </div>
            </div>
            <div className="item images"></div>
        </section>
    )
}