import i18n from "i18next";
import {initReactI18next} from "react-i18next";

i18n.use(initReactI18next).init({
    lng: localStorage.getItem('lang') || 'es', // Default language
    fallbackLng: "es",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                home: 'Home',
                aboutUs: 'About Us',
                products: 'Products',
                ourProducts: 'Our products',
                testimonials: 'Testimonials',
                contact: 'Contacts',
                contactUs: 'Contact Us',
                productWeOffer: 'Products We Offer',
                productWeOfferDetail: 'We offer a wide range of products to meet your protection and security needs.',
                cancerInsurance: 'Cancer Insurance',
                cancerInsuranceDetail: 'Financial protection designed to help you cope with the costs associated with cancer treatment, ensuring you have the support you need to focus on your recovery without financial worries.',
                cancerInsuranceDetailGeneral: 'The cancer insurance offers financial protection in the event of a cancer diagnosis, covering medical expenses, treatments, and other associated costs. This insurance provides peace of mind and financial support, allowing policyholders to focus on their recovery without worrying about the financial impact. The plan is designed to complement other health policies and ensure that patients receive the best possible care without compromising their economic stability.',
                multipleInsurance: '8 Insurance in 1',
                multipleInsuranceDetail: 'A comprehensive plan that combines eight different types of insurance in a single policy, giving you complete and simplified coverage for various needs, from health to accidents.',
                multipleInsuranceDetailGeneral: '8 insurance in 1 is a comprehensive policy that combines multiple coverages in a single product, offering protection against various eventualities. This insurance covers coverage for accidents, serious illnesses, disability, death, medical expenses, hospitalization, among others. With 8 in 1 insurance, policyholders and their families can enjoy greater peace of mind and financial security, having extensive and complete protection in a single plan.',
                finalExpense: 'Final Expenses',
                finalExpenseDetail: 'Coverage that helps cover costs related to death, including funeral services and other associated expenses, easing the financial burden on your loved ones during difficult times.',
                finalExpenseDetailGeneral: 'Final expense insurance from FSG Seguros is designed to cover costs associated with death, such as funeral expenses and other outstanding financial obligations. This insurance helps ease the financial burden on loved ones at a difficult time, ensuring that final costs are covered without affecting the family\'s financial stability. The plan is accessible and easy to manage, providing peace of mind and support at critical times.',
                retirement: 'Retirement',
                retirementDetail: 'Plan designed to ensure a comfortable and secure retirement, offering financial benefits that allow you to maintain your lifestyle and enjoy your golden years with peace of mind and stability.',
                retirementDetailGeneral: 'The FSG Seguros retirement product is designed to provide financial security during your retirement years. This retirement plan offers a variety of investment and savings options that adapt to individual needs, guaranteeing a constant and secure income stream for the future. With this product, policyholders can enjoy their retirement with peace of mind, knowing that their finances are protected and well managed.',
                viewDetail: 'View detail',
                aboutUsQuestion: 'About us?',
                aboutUsQuestionDetail1: 'At Financial Support Group, we are an agency with more than 20 years of experience dedicated to providing personalized and reliable insurance solutions. Our team is made up of more than 30 professionals committed to excellence in service, offering a wide range of insurance products to protect you and your family at all stages of life.',
                aboutUsQuestionDetail2: 'We pride ourselves on our deep understanding of the Puerto Rican market and our strong alliances with more than eight leading insurance companies.',
                phoneNumber: 'Phone Number',
                email: 'Email Address',
                address: 'Address',
                name: 'Name',
                subject: 'Subject',
                message: 'Message',
                sendMessage: 'Send Message',
                findUs: 'Find Us',
                getAllBenefit: 'Get all the benefits',
                cancer: 'Cancer',
                cancerDetail: 'Receive up to $57,000 for first cancer diagnosis. Also, you will have more than 50 benefits to beat cancer',
                accident: 'Accident',
                accidentDetail: 'Fractures, dislocations, $75 for each stitch, doctor visits and more than 40 benefits for the accident area. You will also have life insurance for accidental death and dismemberment of up to $100,000',
                hospitalization: 'Hospitalization',
                hospitalizationDetail: 'Unlimited hospitalization, receive up to $300 per day per hospitalization.',
                fearedDiseases: 'Feared diseases',
                fearedDiseasesDetail: 'In addition to cancer, you will also be covered for 50 dreaded diseases. Some are: Parkinson\'s, Leptospirosis, AH1N1, AIDS, Chikungunya, Fibromyalgia among others.',
                transplant: 'Organ transplant',
                transplantDetail: 'If you need an organ transplant, it is important that you have financial support. You receive up to $30,000 if you need an organ transplant.',
                inability: 'Inability',
                inabilityDetail: 'Receive up to $1,000 Monthly in case of disability',
                convalescence: 'Convalescence',
                convalescenceDetail: 'Receive up to $100 a day for convalescing at home.',
                funeralExpense: 'Funeral Expenses',
                funeralExpenseDetail: 'Receive up to $7,500 to cover funeral expenses if you have not won the fight against illnesses covered under the policy.',
                prices: 'Prices',
                family: 'Family',
                familyDetail: 'The family 100% insured benefits',
                couple: 'Couple',
                coupleDetail: 'Two people insured. 100% Benefits',
                individual: 'Individual',
                individualDetail: 'An insured person. 100% Benefits',
                priceFrom: 'from',
                slogan: '"If it is <span>INSURANCE</span>, your family and mine are just one"',
                cta1: 'Contact us now for a free orientation and find the perfect insurance for you and your family.',
                successContact: 'Thank you for contacting us, one of our representatives will be touch you.',
                errorContact: 'The Information could not be sent at this time, please try again later.',
                contactTerm: 'By completing and submitting this form, you agree and consent that the information provided may be used by Financial Support Group Inc. to contact you via phone calls or SMS messages for tracking and communication purposes. We value your privacy and are committed to protecting your personal information. If at any time you wish to stop receiving these communications, please contact us at Office@fsgseguros.com or follow the instructions provided in our communications.'
            }
        },
        es: {
            translation: {
                home: 'Inicio',
                aboutUs: 'Sobre Nosotros',
                products: 'Productos',
                ourProducts: 'Nuestros Productos',
                testimonials: 'Testimonio',
                contact: 'Contáctanos',
                contactUs: 'Contáctanos',
                productWeOffer: 'Productos que Ofrecemos',
                productWeOfferDetail: 'Ofrecemos una amplia gama de productos para satisfacer tus necesidades de protección y seguridad.',
                cancerInsurance: 'Seguro de Cáncer',
                cancerInsuranceDetail: 'Protección financiera diseñada para ayudarte a enfrentar los costos asociados con el tratamiento del cáncer, asegurando que tengas el apoyo necesario para enfocarte en tu recuperación sin preocupaciones económicas.',
                cancerInsuranceDetailGeneral: 'El seguro de cáncer de FSG Seguros ofrece protección financiera en caso de diagnóstico de   cáncer, cubriendo gastos médicos, tratamientos y otros costos asociados. Este seguro proporciona tranquilidad y apoyo económico, permitiendo a los asegurados enfocarse en su recuperación sin preocuparse por el impacto financiero. El plan está diseñado para complementar otras pólizas de salud y asegurar que los pacientes reciban la mejor atención posible sin comprometer su estabilidad económica.',
                multipleInsurance: '8 Seguros en 1',
                multipleInsuranceDetail: 'Un plan integral que combina ocho tipos de seguros diferentes en una sola póliza, brindándote una cobertura completa y simplificada para diversas necesidades, desde salud hasta accidentes.',
                multipleInsuranceDetailGeneral: '8 seguros en 1 es una póliza integral que combina múltiples coberturas en un solo producto, ofreciendo protección contra diversas eventualidades. Este seguro abarca cobertura por accidentes, enfermedades graves, invalidez, fallecimiento, gastos médicos, hospitalización, entre otros. Con el seguro 8 en 1, los asegurados y sus familias pueden disfrutar de una mayor tranquilidad y seguridad financiera, al contar con una protección amplia y completa en un solo plan.',
                finalExpense: 'Gastos Finales',
                finalExpenseDetail: 'Cobertura que ayuda a cubrir los costos relacionados con el fallecimiento, incluyendo servicios funerarios y otros gastos asociados, aliviando la carga financiera de tus seres queridos en momentos difíciles.',
                finalExpenseDetailGeneral: 'El seguro de gastos finales de FSG Seguros está diseñado para cubrir los costos asociados con el fallecimiento, como los gastos funerarios y otras obligaciones financieras pendientes. Este seguro ayuda a aliviar la carga económica sobre los seres queridos en un momento difícil, asegurando que los costos finales estén cubiertos sin afectar la estabilidad financiera de la familia. El plan es accesible y fácil de gestionar, proporcionando tranquilidad y apoyo en momentos críticos.',
                retirement: 'Retiro',
                retirementDetail: 'Plan diseñado para asegurar una jubilación cómoda y segura, ofreciendo beneficios financieros que te permiten mantener tu estilo de vida y disfrutar de tus años dorados con tranquilidad y estabilidad.',
                retirementDetailGeneral: 'El producto de retiro de FSG Seguros está diseñado para proporcionar seguridad financiera durante los años de jubilación. Este plan de retiro ofrece una variedad de opciones de inversión y ahorro que se adaptan a las necesidades individuales, garantizando un flujo de ingresos constante y seguro para el futuro. Con este producto, los asegurados pueden disfrutar de su jubilación con tranquilidad, sabiendo que sus finanzas están protegidas y bien gestionadas.',
                viewDetail: 'Ver detalle',
                aboutUsQuestion: '¿Quiénes Somos?',
                aboutUsQuestionDetail1: 'En Financial Support Group, somos una agencia con más de 20 años de experiencia dedicada a brindar soluciones de seguros personalizadas y confiables. Nuestro equipo está compuesto por más de 30 profesionales comprometidos con la excelencia en el servicio, ofreciendo una amplia gama de productos de seguros para protegerte a ti y a tu familia en todas las etapas de la vida.',
                aboutUsQuestionDetail2: 'Nos enorgullecemos de nuestra profunda comprensión del mercado puertorriqueño y de nuestras fuertes alianzas con más de ocho compañías de seguros líderes.',
                phoneNumber: 'Número de teléfono',
                email: 'Correo Electrónico',
                address: 'Dirección',
                name: 'Nombre',
                subject: 'Asunto',
                message: 'Mensaje',
                sendMessage: 'Enviar Mensaje',
                findUs: 'Encuentranos',
                getAllBenefit: 'Obtén todos los beneficios',
                cancer: 'Cáncer',
                cancerDetail: 'Recibe hasta $57,000 por primer diagnóstico de cáncer. Más también, tendrás más de 50 beneficios para vencer el cáncer',
                accident: 'Accidente',
                accidentDetail: 'Fracturas, dislocaciones, $75 por cada punto de sutura, visitas al médico y más de 40 beneficios para el área de accidente. También tendrá un seguro de vida por muerte accidental y desmembramiento de hasta $100,000',
                hospitalization: 'Hospitalización',
                hospitalizationDetail: 'Hospitalización ilimitada, recibe hasta $300 diarios por hospitalización.',
                fearedDiseases: 'Enfermedades temidas',
                fearedDiseasesDetail: 'Adicional al cáncer también estará cubierto por 50 enfermedades temidas. Algunas son: Parkinson, Leptospirosis, AH1N1, SIDA, Chikungunya, Fibromialgia entre otras.',
                transplant: 'Trasplante de órganos',
                transplantDetail: 'En caso de necesitar un trasplante de órganos es importante que tenga un respaldo económico, Recibe hasta $30,000 en caso de necesitar un trasplante de órganos.',
                inability: 'Incapacidad',
                inabilityDetail: 'Recibe hasta $1,000 Mensuales en caso de incapacidad',
                convalescence: 'Convalecencia',
                convalescenceDetail: 'Recibe hasta $100 diarios por convalecer en tu hogar.',
                funeralExpense: 'Gastos Funerales',
                funeralExpenseDetail: 'Recibe hasta $7,500 para cubrir los gastos fúnebres en caso de no haber ganado la lucha contra las enfermedades cubiertas bajo la póliza.',
                prices: 'Precios',
                family: 'Familiar',
                familyDetail: 'La familia asegurada 100% Beneficios',
                couple: 'Pareja',
                coupleDetail: 'Dos personas aseguradas. 100% Beneficios',
                individual: 'Individual',
                individualDetail: 'Una persona asegurada. 100% Beneficios',
                priceFrom: 'desde',
                slogan: '"Si se trata de <span>SEGUROS</span>, tu familia y la mia son solo una"',
                cta1: 'Contáctanos ahora para una orientación gratuita y encuentra el seguro perfecto para ti y tu familia.',
                successContact: 'Gracias por contáctanos, próximamente uno de nuestros representantes se estará comunicando con usted.',
                errorContact: 'En estos momentos no se pudo enviar la información, inténtelo más tarde.',
                contactTerm: 'Al completar y enviar este formulario, usted acepta y consiente que la información proporcionada podrá ser utilizada por Financial Support Group Inc. para comunicarse con usted a través de llamadas telefónicas o mensajes SMS con fines de seguimiento y comunicación. Valoramos su privacidad y nos comprometemos a proteger su información personal. Si en cualquier momento desea dejar de recibir estas comunicaciones, por favor, contáctenos a Office@fsgseguros.com o siga las instrucciones proporcionadas en nuestras comunicaciones.'
            }
        },
    },
});

export default i18n;