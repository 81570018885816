import {useTranslation} from "react-i18next";

export default function Maps() {
    const { t } = useTranslation();
    return (
        <>
            <h1 style={{textAlign: "center"}}>{t('findUs')}</h1>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3785.4291770691116!2d-66.05914532420468!3d18.418812872273463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c03692d4b565ed7%3A0x5b0a23fa8761e6a2!2sFinancial%20Support%20Group%20San%20Juan!5e0!3m2!1sen!2spr!4v1716777356676!5m2!1sen!2spr"
                width="100%" height="450" style={{border: 0}} allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
        </>
    )
}