import {Outlet} from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/Footer";

import "./i18n";

function App() {
    return (
        <>
            <Header/>
            <section className="app-container">
                <Outlet/>
            </section>
            <Footer/>
        </>
    )
}

export default App;
